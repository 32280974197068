exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-despre-js": () => import("./../../../src/pages/despre.js" /* webpackChunkName: "component---src-pages-despre-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meniuqr-en-js": () => import("./../../../src/pages/meniuqr-en.js" /* webpackChunkName: "component---src-pages-meniuqr-en-js" */),
  "component---src-pages-meniuqr-js": () => import("./../../../src/pages/meniuqr.js" /* webpackChunkName: "component---src-pages-meniuqr-js" */),
  "component---src-pages-politica-cookies-js": () => import("./../../../src/pages/politica-cookies.js" /* webpackChunkName: "component---src-pages-politica-cookies-js" */),
  "component---src-pages-politica-de-confidentialitate-js": () => import("./../../../src/pages/politica-de-confidentialitate.js" /* webpackChunkName: "component---src-pages-politica-de-confidentialitate-js" */)
}

